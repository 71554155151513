$i: 0;
@while $i < 51 {
	.border-radius-#{$i} {
		border-radius: $i * 1px;
	}
	$i: $i + 1;
}

$j: 1;
@while $j <5 {
  .lines-#{$j} {
    line-height: 1.5em;
    height: $j * 1.5em;
    overflow: hidden;
  }
  $j: $j + 1;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;

  &:hover,
  &:focus {
      color: inherit;
      text-decoration: none;
  }
}

.cursor-pointer {
	cursor: pointer;
}

.box-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.more-btn {
  &:hover {
    .left-arrow {
      transition: all .3s;
      margin-right: 6px;
    }
  }
}

// Linear Gradient Color
.secondary {
  background-image: linear-gradient(to right, #10246A, #1660C9);
}

.bg-gray3 {
  background-color: #E7ECF5;
}

.owl-stage {
  float: left !important;
}

.owl-dots {
  @media (max-width: 1024px) {
    margin-top: 20px !important;
    display: none !important;
  }
}

.owl-nav {
  @media (min-width: 1025px) {
    display: none !important;
  }
}


.hover-img {
  overflow: hidden;
  img {
    transition: transform .5s ease;
  }
  &:hover {
    border-radius: 10px !important;
    img {
      border-radius: 10px !important;
      transform: scale(1.3);
    }
  }
}

.end-hover-btn {
  &:hover {
    img {
      transition: right 0.3s;
      right: 8px;
    }
  }
}

//Form validate
.error-focus {
  &:focus {
    outline-color: #a90520;
  }
}
.error {
  right: 120px;
  top: 5px;
}

.icon-pass {
  left: 15px;
  top: 13px;
}

// Select btn
select {
  -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background-image: url(../../assets/images/Iconionic-ios-arrow-back.png);
   background-repeat: no-repeat;
   background-position: 10px center;
}

.mat-dialog-container {
  height: auto;
  padding: 0;
  margin: 20px 0;
}

button {
  background: transparent;
}

.mat-menu-item,
.mat-list-item,
.mat-button {
  font-family: 'Cairo', sans-serif;;
}

.mat-menu-panel  {
  min-height: 130px !important;
  width: 200px !important;
}


@keyframes hoverLeftArrow {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 0);
  }
}

.hover-more-btn {
  opacity: .8;
  &:hover {
    opacity: 1;
  }
}

.height-30 {
  height: 30px;
}

.height-15 {
  height: 15px;
}

.width-15 {
  width: 15px;
}

.width-30 {
  width: 30px;
}

.min-h-screen	{
  min-height: 100vh;
}

::ng-deep html[dir=ltr] {
  .rotate-arrow {
    display: flex !important;
    transform: rotate(180deg) !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.mat-form-field {
  width: 22%;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 10px !important;
  background-color: #FFF;
}

.mat-form-field-underline {
  width: 0 !important;
}

.profile-img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
}

.width-800 {
  @media (min-width: 1024px) {
    width: 800px;
  }
}

.pattern-line{
  opacity: 20%;
  height: 19px;
}

.eye-icon {
  position: absolute;
  top: 30%;
  left: 2%;
}

.height-40 {
  height: 40px !important;
}
