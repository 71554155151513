@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        $i: 100;

        @while $i < 1000 {

            .weight#{$infix}-#{$i} {
                font-weight: $i !important;
            }

            $i: $i+100;
        }

        $i: 0;

        @while $i < 60 {

            .size#{$infix}-#{$i} {
                font-size: (0.0625rem * (($i * 1) + 10)) !important;
            }

            $i: $i+1;
        }
    }
}

