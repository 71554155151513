@import "theme-colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700&display=swap");
$theme-colors: (
  "primary": $primary,
  "dark": $dark,
  "white": $white,
  "gray1": $gray1,
  "gray2": $gray2,
  "gray4": $gray4,
  "danger": $danger,
);

$font-family-base: "Cairo", sans-serif;
$body-bg: $white;
@import "bootstrap/bootstrap.scss";
@import "typography.scss";
@import "width.scss";
// @import 'generics.scss';

html[dir="ltr"] {
  $start: left;
  $end: right;
  @import "spacing";
  @import "generics.scss";
}
html[dir="rtl"] {
  $start: right;
  $end: left;
  @import "spacing";
  @import "generics.scss";
}
